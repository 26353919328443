import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

import notFoundImage from '../../assets/vectors/page_not_found.svg';

import { formatUrl } from '../../utils/url';
import { Urls } from '../urls';

import { Placeholder, Size } from '../../components/atoms/Placeholder/Placeholder';
import { Copy } from '../../components/atoms/Typography';
import { LayoutCentered } from '../../components/atoms/Layout/Layout';

export const NotFound404 = () => {
  return (
    <>
      <Helmet title="Not found" />
      <LayoutCentered>
        <Placeholder
          icon={notFoundImage}
          iconAlt="404"
          iconSize={Size.LARGE}
          title="Page Not Found"
          description={
            <>
              <Copy marginBottom={20}>No trace of intelligent life down here.</Copy>
              <Copy>
                Beam me up to the <Link to={formatUrl(Urls.Home)}>homepage</Link>
              </Copy>
            </>
          }
        />
      </LayoutCentered>
    </>
  );
};
